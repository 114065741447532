var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto",
      "p": ['1rem', '0px']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: {
          name: 'client.index'
        }
      }, {
        label: 'Akun',
        href: {
          name: 'client.profile'
        }
      }, {
        label: 'Pusat Bantuan',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "h": "75vh",
      "background": "#FFFFFF",
      "border": "1px solid #f2f2f2",
      "box-shadow": "4px 4px 50px 5px rgba(0, 0, 0, 0.05)",
      "border-radius": "16px",
      "w": "100%",
      "p": "8px",
      "align": "center"
    }
  }, [_vm._v(" Pusat Bantuan ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }