<template>
  <c-box
    w="100%"
    mx="auto"
    :p="['1rem', '0px']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          {
            label: 'Dashboard',
            href: { name: 'client.index' }
          },
          {
            label: 'Akun',
            href: { name: 'client.profile' }
          },
          {
            label: 'Pusat Bantuan',
            isCurrent: true,
          },
        ]"
      />
    </Portal>
    <c-box
      h="75vh"
      background="#FFFFFF"
      border="1px solid #f2f2f2"
      box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.05)"
      border-radius="16px"
      w="100%"
      p="8px"
      align="center"
    >
      Pusat Bantuan
    </c-box>
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'

export default {
  components: {
    BreadcrumbPath,
  },
}
</script>
